/* pages/error.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  text-align: center;
}

.image {
  max-width: 100px;
  margin-bottom: 1rem;
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.homeLink {
  font-size: 1.1rem;
  color: #0070f3;
  text-decoration: none;
}

.homeLink:hover {
  text-decoration: underline;
}
